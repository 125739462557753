
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'


dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)

dayjs.locale('de')
dayjs.tz.setDefault('Central European Time')



export default dayjs
