import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import val_de from './vee-validate/val_de.json'
import val_en from './vee-validate/val_en.json'
import zxcvbn from "zxcvbn";
import {isValidIBAN, electronicFormatIBAN} from 'ibantools';

const url_regex = '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$'; // fragment locator

const date_regex = '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$';

const rulesForErrorMessage = [
    'validation_pending'
]

export default defineNuxtPlugin((nuxtApp) => {

    /**************
     * Custom rules
     **************/

    defineRule('verify_password', value => value && zxcvbn(value).score > 2);
    defineRule('confirm_password', (value, [password]) => value === password);
    defineRule('verify_url', value => new RegExp(url_regex, 'i').test(value));
    defineRule('verify_iban', value => {
        return isValidIBAN(electronicFormatIBAN(value));
    });
    defineRule('same', (value, [otherValue]) => value == otherValue);
    defineRule('decimal', (value, [digits]) => {
        if (value.toString().indexOf(".") > -1 && value.toString().substring(value.toString().indexOf(".") + 1).length > digits) {
            return `Der Wert darf höchstens ${digits} Nachkommastelle` + (digits > 1 ? 'n' : '') + ` haben`
        } else {
            return true
        }
    });
    defineRule('currency', (value) => {
        if (value.toString().indexOf(".") > -1 && value.toString().substring(value.toString().indexOf(".") + 1).length > 2) {
            return `Der Wert darf höchstens 2 Nachkommastellen haben`
        } else {
            return true
        }
    });
    defineRule('date', value => {
        return (new RegExp(date_regex).test(value) ? true : "Das Datum muss im Format JJJJ-MM-TT sein")
    });

    rulesForErrorMessage.forEach(rule => defineRule(rule, () => false))

    /**************
     * Configuration
     **************/

    Object.keys(AllRules).forEach(rule => {
        defineRule(rule, AllRules[rule]);
    });

    configure({
        generateMessage: localize({
            en: {...en, messages: {...en.messages, ...val_en}},
            de: {...de, messages: {...de.messages, ...val_de}}
        })
    });

    setLocale('de');

    nuxtApp.vueApp.component("VField", Field)
    nuxtApp.vueApp.component("VForm", Form)
    nuxtApp.vueApp.component("ErrorMessage", ErrorMessage)

    configure({
        classes: {
            valid: 'is-valid',
            invalid: 'is-invalid',
            dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
        }
    });
})