import { default as index3a3qKMEq4HMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/auth/index.vue?macro=true";
import { default as indexFVdwAYHHSkMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93zHxbGGEcqKMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/formProcess/[id].vue?macro=true";
import { default as _91id_93SN8TskN417Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/formProcess/success/[id].vue?macro=true";
import { default as indexY5DVoUpT5jMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/index.vue?macro=true";
import { default as _91region_93GTDpEKA6FuMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/jugend-zaehlt/[region].vue?macro=true";
import { default as organisationBaseDataYlirzOis6oMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as indexzTZSJLzMu1Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/processes/index.vue?macro=true";
import { default as processeshuonKXpcCuMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/processes.vue?macro=true";
import { default as agsyCwDaARcprMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/ags.vue?macro=true";
import { default as categoriesC3zAaYyaCDMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/categories.vue?macro=true";
import { default as formBaseDatacbhGRtbayHMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id]/formBaseData.vue?macro=true";
import { default as formClassXVN7zlOYkFMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id]/formClass.vue?macro=true";
import { default as _91id_93ZPwGnK3YiXMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id].vue?macro=true";
import { default as indexOPWHUGtOpyMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/index.vue?macro=true";
import { default as formskns9nylHkbMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms.vue?macro=true";
import { default as fundingProgramBaseDataeKIapFKbo5Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramBaseData.vue?macro=true";
import { default as fundingProgramRightsGInIR0kOmLMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramRights.vue?macro=true";
import { default as _91id_93ImOjGKbookMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id].vue?macro=true";
import { default as index1kf1FOspchMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/index.vue?macro=true";
import { default as mailTemplatesjVOZMuU96EMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/mailTemplates.vue?macro=true";
import { default as roles10b99KAyQBMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/roles.vue?macro=true";
import { default as statisticsDnus4evkWjMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statistics.vue?macro=true";
import { default as statusBaseDataAL9ASRiVg7Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/[id]/statusBaseData.vue?macro=true";
import { default as _91id_93LxkydznxqwMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/[id].vue?macro=true";
import { default as indexOusadL82bWMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/index.vue?macro=true";
import { default as yearsqJW2I6OyCpMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/years.vue?macro=true";
import { default as indexyRxPAL3IeqMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/index.vue?macro=true";
import { default as parishAgeGroupsL8JiAzMMuxMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id]/parishAgeGroups.vue?macro=true";
import { default as parishBaseDataP4RQVX3tobMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id]/parishBaseData.vue?macro=true";
import { default as _91id_93YtLeCtM7n7Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id].vue?macro=true";
import { default as indexWHkl3eSIZBMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/index.vue?macro=true";
import { default as parishesOiZpLx0VvPMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes.vue?macro=true";
import { default as regionActivitiesQhnrbIaF2aMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionActivities.vue?macro=true";
import { default as regionAgeGroups3C6Vjc06GUMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionAgeGroups.vue?macro=true";
import { default as regionBaseData8vYbTpBVRfMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionBaseData.vue?macro=true";
import { default as regionDiaconiasTKR8wzI4w2Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionDiaconias.vue?macro=true";
import { default as regionParishesD1VZTWZdZ9Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionParishes.vue?macro=true";
import { default as _91id_93NVoKVh1ye8Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id].vue?macro=true";
import { default as indexhZ4XLWtn2fMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/index.vue?macro=true";
import { default as regionsNvViuWN5TqMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions.vue?macro=true";
import { default as paymentzBnBVgHazGMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/payment.vue?macro=true";
import { default as processhuqRIaq1TWMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/process.vue?macro=true";
import { default as _91id_93EAQUpzbvAOMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders/[id].vue?macro=true";
import { default as indexSRE3byNBThMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders/index.vue?macro=true";
import { default as loginProvidersPDyxmiDodgMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders.vue?macro=true";
import { default as organisationActivitiesRkuhxdKsTmMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationActivities.vue?macro=true";
import { default as organisationBaseDatak44v4h9wc4Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as organisationCategories1nktoSQQ3gMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationCategories.vue?macro=true";
import { default as organisationUsersD6l6spuehQMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationUsers.vue?macro=true";
import { default as _91id_93QpWm5q8tplMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id].vue?macro=true";
import { default as indexEtQEurMC4VMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/index.vue?macro=true";
import { default as organisationsDC5lygl8H0Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations.vue?macro=true";
import { default as userActivitiesNpUcrPH5g9Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataH2PMwVuQVjMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id]/userBaseData.vue?macro=true";
import { default as _91id_93rvalUrukKsMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id].vue?macro=true";
import { default as index8v3ECnoBOxMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/index.vue?macro=true";
import { default as usersdxTBIJBeZoMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users.vue?macro=true";
import { default as statisticslmc5wk1uUbMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/statistics.vue?macro=true";
import { default as serviceqSWCNZMSMqMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service.vue?macro=true";
import { default as languagegua6UEIwycMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/language.vue?macro=true";
import { default as organisationActivities0bpHsxHlpWMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationActivities.vue?macro=true";
import { default as organisationBaseDatac00QnwK23uMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as organisationCategoriespLHXQQjZjhMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationCategories.vue?macro=true";
import { default as organisationUsersA4vg1DzHxvMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationUsers.vue?macro=true";
import { default as _91id_937tx4GV4yLpMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id].vue?macro=true";
import { default as userActivitiesRkowh9b3OTMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataRerpdZ2zuKMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id]/userBaseData.vue?macro=true";
import { default as _91id_939SJCAVoi1QMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id].vue?macro=true";
import { default as settingsI55iluHhQ7Meta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings.vue?macro=true";
import { default as signaturee5nm2afNvqMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/signature.vue?macro=true";
import { default as statisticsdJ2dx5BqueMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/statistics.vue?macro=true";
import { default as _91_46_46_46slug_93cAHGV0QFGaMeta } from "/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: index3a3qKMEq4HMeta?.name ?? "auth",
    path: index3a3qKMEq4HMeta?.path ?? "/auth",
    meta: index3a3qKMEq4HMeta || {},
    alias: index3a3qKMEq4HMeta?.alias || [],
    redirect: index3a3qKMEq4HMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexFVdwAYHHSkMeta?.name ?? "auth-social-organisation-provider",
    path: indexFVdwAYHHSkMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexFVdwAYHHSkMeta || {},
    alias: indexFVdwAYHHSkMeta?.alias || [],
    redirect: indexFVdwAYHHSkMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zHxbGGEcqKMeta?.name ?? "formProcess-id",
    path: _91id_93zHxbGGEcqKMeta?.path ?? "/formProcess/:id()",
    meta: _91id_93zHxbGGEcqKMeta || {},
    alias: _91id_93zHxbGGEcqKMeta?.alias || [],
    redirect: _91id_93zHxbGGEcqKMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/formProcess/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93SN8TskN417Meta?.name ?? "formProcess-success-id",
    path: _91id_93SN8TskN417Meta?.path ?? "/formProcess/success/:id()",
    meta: _91id_93SN8TskN417Meta || {},
    alias: _91id_93SN8TskN417Meta?.alias || [],
    redirect: _91id_93SN8TskN417Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/formProcess/success/[id].vue").then(m => m.default || m)
  },
  {
    name: indexY5DVoUpT5jMeta?.name ?? "index",
    path: indexY5DVoUpT5jMeta?.path ?? "/",
    meta: indexY5DVoUpT5jMeta || {},
    alias: indexY5DVoUpT5jMeta?.alias || [],
    redirect: indexY5DVoUpT5jMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91region_93GTDpEKA6FuMeta?.name ?? "jugend-zaehlt-region",
    path: _91region_93GTDpEKA6FuMeta?.path ?? "/jugend-zaehlt/:region()",
    meta: _91region_93GTDpEKA6FuMeta || {},
    alias: _91region_93GTDpEKA6FuMeta?.alias || [],
    redirect: _91region_93GTDpEKA6FuMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/jugend-zaehlt/[region].vue").then(m => m.default || m)
  },
  {
    name: organisationBaseDataYlirzOis6oMeta?.name ?? "organisations-id-organisationBaseData",
    path: organisationBaseDataYlirzOis6oMeta?.path ?? "/organisations/:id()/organisationBaseData",
    meta: organisationBaseDataYlirzOis6oMeta || {},
    alias: organisationBaseDataYlirzOis6oMeta?.alias || [],
    redirect: organisationBaseDataYlirzOis6oMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  },
  {
    path: processeshuonKXpcCuMeta?.path ?? "/processes",
    children: [
  {
    name: indexzTZSJLzMu1Meta?.name ?? "processes",
    path: indexzTZSJLzMu1Meta?.path ?? "",
    meta: indexzTZSJLzMu1Meta || {},
    alias: indexzTZSJLzMu1Meta?.alias || [],
    redirect: indexzTZSJLzMu1Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/processes/index.vue").then(m => m.default || m)
  }
],
    name: processeshuonKXpcCuMeta?.name ?? undefined,
    meta: processeshuonKXpcCuMeta || {},
    alias: processeshuonKXpcCuMeta?.alias || [],
    redirect: processeshuonKXpcCuMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/processes.vue").then(m => m.default || m)
  },
  {
    path: serviceqSWCNZMSMqMeta?.path ?? "/service",
    children: [
  {
    name: agsyCwDaARcprMeta?.name ?? "service-basicData-ags",
    path: agsyCwDaARcprMeta?.path ?? "basicData/ags",
    meta: agsyCwDaARcprMeta || {},
    alias: agsyCwDaARcprMeta?.alias || [],
    redirect: agsyCwDaARcprMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/ags.vue").then(m => m.default || m)
  },
  {
    name: categoriesC3zAaYyaCDMeta?.name ?? "service-basicData-categories",
    path: categoriesC3zAaYyaCDMeta?.path ?? "basicData/categories",
    meta: categoriesC3zAaYyaCDMeta || {},
    alias: categoriesC3zAaYyaCDMeta?.alias || [],
    redirect: categoriesC3zAaYyaCDMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/categories.vue").then(m => m.default || m)
  },
  {
    path: formskns9nylHkbMeta?.path ?? "basicData/forms",
    children: [
  {
    name: _91id_93ZPwGnK3YiXMeta?.name ?? "service-basicData-forms-id",
    path: _91id_93ZPwGnK3YiXMeta?.path ?? ":id()",
    children: [
  {
    name: formBaseDatacbhGRtbayHMeta?.name ?? "service-basicData-forms-id-formBaseData",
    path: formBaseDatacbhGRtbayHMeta?.path ?? "formBaseData",
    meta: formBaseDatacbhGRtbayHMeta || {},
    alias: formBaseDatacbhGRtbayHMeta?.alias || [],
    redirect: formBaseDatacbhGRtbayHMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id]/formBaseData.vue").then(m => m.default || m)
  },
  {
    name: formClassXVN7zlOYkFMeta?.name ?? "service-basicData-forms-id-formClass",
    path: formClassXVN7zlOYkFMeta?.path ?? "formClass",
    meta: formClassXVN7zlOYkFMeta || {},
    alias: formClassXVN7zlOYkFMeta?.alias || [],
    redirect: formClassXVN7zlOYkFMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id]/formClass.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ZPwGnK3YiXMeta || {},
    alias: _91id_93ZPwGnK3YiXMeta?.alias || [],
    redirect: _91id_93ZPwGnK3YiXMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOPWHUGtOpyMeta?.name ?? "service-basicData-forms",
    path: indexOPWHUGtOpyMeta?.path ?? "",
    meta: indexOPWHUGtOpyMeta || {},
    alias: indexOPWHUGtOpyMeta?.alias || [],
    redirect: indexOPWHUGtOpyMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms/index.vue").then(m => m.default || m)
  }
],
    name: formskns9nylHkbMeta?.name ?? undefined,
    meta: formskns9nylHkbMeta || {},
    alias: formskns9nylHkbMeta?.alias || [],
    redirect: formskns9nylHkbMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/forms.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ImOjGKbookMeta?.name ?? "service-basicData-fundingPrograms-id",
    path: _91id_93ImOjGKbookMeta?.path ?? "basicData/fundingPrograms/:id()",
    children: [
  {
    name: fundingProgramBaseDataeKIapFKbo5Meta?.name ?? "service-basicData-fundingPrograms-id-fundingProgramBaseData",
    path: fundingProgramBaseDataeKIapFKbo5Meta?.path ?? "fundingProgramBaseData",
    meta: fundingProgramBaseDataeKIapFKbo5Meta || {},
    alias: fundingProgramBaseDataeKIapFKbo5Meta?.alias || [],
    redirect: fundingProgramBaseDataeKIapFKbo5Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramBaseData.vue").then(m => m.default || m)
  },
  {
    name: fundingProgramRightsGInIR0kOmLMeta?.name ?? "service-basicData-fundingPrograms-id-fundingProgramRights",
    path: fundingProgramRightsGInIR0kOmLMeta?.path ?? "fundingProgramRights",
    meta: fundingProgramRightsGInIR0kOmLMeta || {},
    alias: fundingProgramRightsGInIR0kOmLMeta?.alias || [],
    redirect: fundingProgramRightsGInIR0kOmLMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id]/fundingProgramRights.vue").then(m => m.default || m)
  }
],
    meta: _91id_93ImOjGKbookMeta || {},
    alias: _91id_93ImOjGKbookMeta?.alias || [],
    redirect: _91id_93ImOjGKbookMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/[id].vue").then(m => m.default || m)
  },
  {
    name: index1kf1FOspchMeta?.name ?? "service-basicData-fundingPrograms",
    path: index1kf1FOspchMeta?.path ?? "basicData/fundingPrograms",
    meta: index1kf1FOspchMeta || {},
    alias: index1kf1FOspchMeta?.alias || [],
    redirect: index1kf1FOspchMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/fundingPrograms/index.vue").then(m => m.default || m)
  },
  {
    name: mailTemplatesjVOZMuU96EMeta?.name ?? "service-basicData-mailTemplates",
    path: mailTemplatesjVOZMuU96EMeta?.path ?? "basicData/mailTemplates",
    meta: mailTemplatesjVOZMuU96EMeta || {},
    alias: mailTemplatesjVOZMuU96EMeta?.alias || [],
    redirect: mailTemplatesjVOZMuU96EMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/mailTemplates.vue").then(m => m.default || m)
  },
  {
    name: roles10b99KAyQBMeta?.name ?? "service-basicData-roles",
    path: roles10b99KAyQBMeta?.path ?? "basicData/roles",
    meta: roles10b99KAyQBMeta || {},
    alias: roles10b99KAyQBMeta?.alias || [],
    redirect: roles10b99KAyQBMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/roles.vue").then(m => m.default || m)
  },
  {
    name: statisticsDnus4evkWjMeta?.name ?? "service-basicData-statistics",
    path: statisticsDnus4evkWjMeta?.path ?? "basicData/statistics",
    meta: statisticsDnus4evkWjMeta || {},
    alias: statisticsDnus4evkWjMeta?.alias || [],
    redirect: statisticsDnus4evkWjMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LxkydznxqwMeta?.name ?? "service-basicData-statuses-id",
    path: _91id_93LxkydznxqwMeta?.path ?? "basicData/statuses/:id()",
    children: [
  {
    name: statusBaseDataAL9ASRiVg7Meta?.name ?? "service-basicData-statuses-id-statusBaseData",
    path: statusBaseDataAL9ASRiVg7Meta?.path ?? "statusBaseData",
    meta: statusBaseDataAL9ASRiVg7Meta || {},
    alias: statusBaseDataAL9ASRiVg7Meta?.alias || [],
    redirect: statusBaseDataAL9ASRiVg7Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/[id]/statusBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93LxkydznxqwMeta || {},
    alias: _91id_93LxkydznxqwMeta?.alias || [],
    redirect: _91id_93LxkydznxqwMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOusadL82bWMeta?.name ?? "service-basicData-statuses",
    path: indexOusadL82bWMeta?.path ?? "basicData/statuses",
    meta: indexOusadL82bWMeta || {},
    alias: indexOusadL82bWMeta?.alias || [],
    redirect: indexOusadL82bWMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/statuses/index.vue").then(m => m.default || m)
  },
  {
    name: yearsqJW2I6OyCpMeta?.name ?? "service-basicData-years",
    path: yearsqJW2I6OyCpMeta?.path ?? "basicData/years",
    meta: yearsqJW2I6OyCpMeta || {},
    alias: yearsqJW2I6OyCpMeta?.alias || [],
    redirect: yearsqJW2I6OyCpMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/basicData/years.vue").then(m => m.default || m)
  },
  {
    name: indexyRxPAL3IeqMeta?.name ?? "service",
    path: indexyRxPAL3IeqMeta?.path ?? "",
    meta: indexyRxPAL3IeqMeta || {},
    alias: indexyRxPAL3IeqMeta?.alias || [],
    redirect: indexyRxPAL3IeqMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/index.vue").then(m => m.default || m)
  },
  {
    path: parishesOiZpLx0VvPMeta?.path ?? "jugendzaehlt/parishes",
    children: [
  {
    name: _91id_93YtLeCtM7n7Meta?.name ?? "service-jugendzaehlt-parishes-id",
    path: _91id_93YtLeCtM7n7Meta?.path ?? ":id()",
    children: [
  {
    name: parishAgeGroupsL8JiAzMMuxMeta?.name ?? "service-jugendzaehlt-parishes-id-parishAgeGroups",
    path: parishAgeGroupsL8JiAzMMuxMeta?.path ?? "parishAgeGroups",
    meta: parishAgeGroupsL8JiAzMMuxMeta || {},
    alias: parishAgeGroupsL8JiAzMMuxMeta?.alias || [],
    redirect: parishAgeGroupsL8JiAzMMuxMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id]/parishAgeGroups.vue").then(m => m.default || m)
  },
  {
    name: parishBaseDataP4RQVX3tobMeta?.name ?? "service-jugendzaehlt-parishes-id-parishBaseData",
    path: parishBaseDataP4RQVX3tobMeta?.path ?? "parishBaseData",
    meta: parishBaseDataP4RQVX3tobMeta || {},
    alias: parishBaseDataP4RQVX3tobMeta?.alias || [],
    redirect: parishBaseDataP4RQVX3tobMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id]/parishBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93YtLeCtM7n7Meta || {},
    alias: _91id_93YtLeCtM7n7Meta?.alias || [],
    redirect: _91id_93YtLeCtM7n7Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWHkl3eSIZBMeta?.name ?? "service-jugendzaehlt-parishes",
    path: indexWHkl3eSIZBMeta?.path ?? "",
    meta: indexWHkl3eSIZBMeta || {},
    alias: indexWHkl3eSIZBMeta?.alias || [],
    redirect: indexWHkl3eSIZBMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes/index.vue").then(m => m.default || m)
  }
],
    name: parishesOiZpLx0VvPMeta?.name ?? undefined,
    meta: parishesOiZpLx0VvPMeta || {},
    alias: parishesOiZpLx0VvPMeta?.alias || [],
    redirect: parishesOiZpLx0VvPMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/parishes.vue").then(m => m.default || m)
  },
  {
    path: regionsNvViuWN5TqMeta?.path ?? "jugendzaehlt/regions",
    children: [
  {
    name: _91id_93NVoKVh1ye8Meta?.name ?? "service-jugendzaehlt-regions-id",
    path: _91id_93NVoKVh1ye8Meta?.path ?? ":id()",
    children: [
  {
    name: regionActivitiesQhnrbIaF2aMeta?.name ?? "service-jugendzaehlt-regions-id-regionActivities",
    path: regionActivitiesQhnrbIaF2aMeta?.path ?? "regionActivities",
    meta: regionActivitiesQhnrbIaF2aMeta || {},
    alias: regionActivitiesQhnrbIaF2aMeta?.alias || [],
    redirect: regionActivitiesQhnrbIaF2aMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionActivities.vue").then(m => m.default || m)
  },
  {
    name: regionAgeGroups3C6Vjc06GUMeta?.name ?? "service-jugendzaehlt-regions-id-regionAgeGroups",
    path: regionAgeGroups3C6Vjc06GUMeta?.path ?? "regionAgeGroups",
    meta: regionAgeGroups3C6Vjc06GUMeta || {},
    alias: regionAgeGroups3C6Vjc06GUMeta?.alias || [],
    redirect: regionAgeGroups3C6Vjc06GUMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionAgeGroups.vue").then(m => m.default || m)
  },
  {
    name: regionBaseData8vYbTpBVRfMeta?.name ?? "service-jugendzaehlt-regions-id-regionBaseData",
    path: regionBaseData8vYbTpBVRfMeta?.path ?? "regionBaseData",
    meta: regionBaseData8vYbTpBVRfMeta || {},
    alias: regionBaseData8vYbTpBVRfMeta?.alias || [],
    redirect: regionBaseData8vYbTpBVRfMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionBaseData.vue").then(m => m.default || m)
  },
  {
    name: regionDiaconiasTKR8wzI4w2Meta?.name ?? "service-jugendzaehlt-regions-id-regionDiaconias",
    path: regionDiaconiasTKR8wzI4w2Meta?.path ?? "regionDiaconias",
    meta: regionDiaconiasTKR8wzI4w2Meta || {},
    alias: regionDiaconiasTKR8wzI4w2Meta?.alias || [],
    redirect: regionDiaconiasTKR8wzI4w2Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionDiaconias.vue").then(m => m.default || m)
  },
  {
    name: regionParishesD1VZTWZdZ9Meta?.name ?? "service-jugendzaehlt-regions-id-regionParishes",
    path: regionParishesD1VZTWZdZ9Meta?.path ?? "regionParishes",
    meta: regionParishesD1VZTWZdZ9Meta || {},
    alias: regionParishesD1VZTWZdZ9Meta?.alias || [],
    redirect: regionParishesD1VZTWZdZ9Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id]/regionParishes.vue").then(m => m.default || m)
  }
],
    meta: _91id_93NVoKVh1ye8Meta || {},
    alias: _91id_93NVoKVh1ye8Meta?.alias || [],
    redirect: _91id_93NVoKVh1ye8Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhZ4XLWtn2fMeta?.name ?? "service-jugendzaehlt-regions",
    path: indexhZ4XLWtn2fMeta?.path ?? "",
    meta: indexhZ4XLWtn2fMeta || {},
    alias: indexhZ4XLWtn2fMeta?.alias || [],
    redirect: indexhZ4XLWtn2fMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions/index.vue").then(m => m.default || m)
  }
],
    name: regionsNvViuWN5TqMeta?.name ?? undefined,
    meta: regionsNvViuWN5TqMeta || {},
    alias: regionsNvViuWN5TqMeta?.alias || [],
    redirect: regionsNvViuWN5TqMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/jugendzaehlt/regions.vue").then(m => m.default || m)
  },
  {
    name: paymentzBnBVgHazGMeta?.name ?? "service-payment",
    path: paymentzBnBVgHazGMeta?.path ?? "payment",
    meta: paymentzBnBVgHazGMeta || {},
    alias: paymentzBnBVgHazGMeta?.alias || [],
    redirect: paymentzBnBVgHazGMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/payment.vue").then(m => m.default || m)
  },
  {
    name: processhuqRIaq1TWMeta?.name ?? "service-process",
    path: processhuqRIaq1TWMeta?.path ?? "process",
    meta: processhuqRIaq1TWMeta || {},
    alias: processhuqRIaq1TWMeta?.alias || [],
    redirect: processhuqRIaq1TWMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/process.vue").then(m => m.default || m)
  },
  {
    path: loginProvidersPDyxmiDodgMeta?.path ?? "settings/loginProviders",
    children: [
  {
    name: _91id_93EAQUpzbvAOMeta?.name ?? "service-settings-loginProviders-id",
    path: _91id_93EAQUpzbvAOMeta?.path ?? ":id()",
    meta: _91id_93EAQUpzbvAOMeta || {},
    alias: _91id_93EAQUpzbvAOMeta?.alias || [],
    redirect: _91id_93EAQUpzbvAOMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSRE3byNBThMeta?.name ?? "service-settings-loginProviders",
    path: indexSRE3byNBThMeta?.path ?? "",
    meta: indexSRE3byNBThMeta || {},
    alias: indexSRE3byNBThMeta?.alias || [],
    redirect: indexSRE3byNBThMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders/index.vue").then(m => m.default || m)
  }
],
    name: loginProvidersPDyxmiDodgMeta?.name ?? undefined,
    meta: loginProvidersPDyxmiDodgMeta || {},
    alias: loginProvidersPDyxmiDodgMeta?.alias || [],
    redirect: loginProvidersPDyxmiDodgMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/loginProviders.vue").then(m => m.default || m)
  },
  {
    path: organisationsDC5lygl8H0Meta?.path ?? "settings/organisations",
    children: [
  {
    name: _91id_93QpWm5q8tplMeta?.name ?? "service-settings-organisations-id",
    path: _91id_93QpWm5q8tplMeta?.path ?? ":id()",
    children: [
  {
    name: organisationActivitiesRkuhxdKsTmMeta?.name ?? "service-settings-organisations-id-organisationActivities",
    path: organisationActivitiesRkuhxdKsTmMeta?.path ?? "organisationActivities",
    meta: organisationActivitiesRkuhxdKsTmMeta || {},
    alias: organisationActivitiesRkuhxdKsTmMeta?.alias || [],
    redirect: organisationActivitiesRkuhxdKsTmMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationActivities.vue").then(m => m.default || m)
  },
  {
    name: organisationBaseDatak44v4h9wc4Meta?.name ?? "service-settings-organisations-id-organisationBaseData",
    path: organisationBaseDatak44v4h9wc4Meta?.path ?? "organisationBaseData",
    meta: organisationBaseDatak44v4h9wc4Meta || {},
    alias: organisationBaseDatak44v4h9wc4Meta?.alias || [],
    redirect: organisationBaseDatak44v4h9wc4Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  },
  {
    name: organisationCategories1nktoSQQ3gMeta?.name ?? "service-settings-organisations-id-organisationCategories",
    path: organisationCategories1nktoSQQ3gMeta?.path ?? "organisationCategories",
    meta: organisationCategories1nktoSQQ3gMeta || {},
    alias: organisationCategories1nktoSQQ3gMeta?.alias || [],
    redirect: organisationCategories1nktoSQQ3gMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationCategories.vue").then(m => m.default || m)
  },
  {
    name: organisationUsersD6l6spuehQMeta?.name ?? "service-settings-organisations-id-organisationUsers",
    path: organisationUsersD6l6spuehQMeta?.path ?? "organisationUsers",
    meta: organisationUsersD6l6spuehQMeta || {},
    alias: organisationUsersD6l6spuehQMeta?.alias || [],
    redirect: organisationUsersD6l6spuehQMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id]/organisationUsers.vue").then(m => m.default || m)
  }
],
    meta: _91id_93QpWm5q8tplMeta || {},
    alias: _91id_93QpWm5q8tplMeta?.alias || [],
    redirect: _91id_93QpWm5q8tplMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEtQEurMC4VMeta?.name ?? "service-settings-organisations",
    path: indexEtQEurMC4VMeta?.path ?? "",
    meta: indexEtQEurMC4VMeta || {},
    alias: indexEtQEurMC4VMeta?.alias || [],
    redirect: indexEtQEurMC4VMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations/index.vue").then(m => m.default || m)
  }
],
    name: organisationsDC5lygl8H0Meta?.name ?? undefined,
    meta: organisationsDC5lygl8H0Meta || {},
    alias: organisationsDC5lygl8H0Meta?.alias || [],
    redirect: organisationsDC5lygl8H0Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/organisations.vue").then(m => m.default || m)
  },
  {
    path: usersdxTBIJBeZoMeta?.path ?? "settings/users",
    children: [
  {
    name: _91id_93rvalUrukKsMeta?.name ?? "service-settings-users-id",
    path: _91id_93rvalUrukKsMeta?.path ?? ":id()",
    children: [
  {
    name: userActivitiesNpUcrPH5g9Meta?.name ?? "service-settings-users-id-userActivities",
    path: userActivitiesNpUcrPH5g9Meta?.path ?? "userActivities",
    meta: userActivitiesNpUcrPH5g9Meta || {},
    alias: userActivitiesNpUcrPH5g9Meta?.alias || [],
    redirect: userActivitiesNpUcrPH5g9Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataH2PMwVuQVjMeta?.name ?? "service-settings-users-id-userBaseData",
    path: userBaseDataH2PMwVuQVjMeta?.path ?? "userBaseData",
    meta: userBaseDataH2PMwVuQVjMeta || {},
    alias: userBaseDataH2PMwVuQVjMeta?.alias || [],
    redirect: userBaseDataH2PMwVuQVjMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93rvalUrukKsMeta || {},
    alias: _91id_93rvalUrukKsMeta?.alias || [],
    redirect: _91id_93rvalUrukKsMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index8v3ECnoBOxMeta?.name ?? "service-settings-users",
    path: index8v3ECnoBOxMeta?.path ?? "",
    meta: index8v3ECnoBOxMeta || {},
    alias: index8v3ECnoBOxMeta?.alias || [],
    redirect: index8v3ECnoBOxMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users/index.vue").then(m => m.default || m)
  }
],
    name: usersdxTBIJBeZoMeta?.name ?? undefined,
    meta: usersdxTBIJBeZoMeta || {},
    alias: usersdxTBIJBeZoMeta?.alias || [],
    redirect: usersdxTBIJBeZoMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/settings/users.vue").then(m => m.default || m)
  },
  {
    name: statisticslmc5wk1uUbMeta?.name ?? "service-statistics",
    path: statisticslmc5wk1uUbMeta?.path ?? "statistics",
    meta: statisticslmc5wk1uUbMeta || {},
    alias: statisticslmc5wk1uUbMeta?.alias || [],
    redirect: statisticslmc5wk1uUbMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service/statistics.vue").then(m => m.default || m)
  }
],
    name: serviceqSWCNZMSMqMeta?.name ?? undefined,
    meta: serviceqSWCNZMSMqMeta || {},
    alias: serviceqSWCNZMSMqMeta?.alias || [],
    redirect: serviceqSWCNZMSMqMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/service.vue").then(m => m.default || m)
  },
  {
    name: settingsI55iluHhQ7Meta?.name ?? "settings",
    path: settingsI55iluHhQ7Meta?.path ?? "/settings",
    children: [
  {
    name: languagegua6UEIwycMeta?.name ?? "settings-language",
    path: languagegua6UEIwycMeta?.path ?? "language",
    meta: languagegua6UEIwycMeta || {},
    alias: languagegua6UEIwycMeta?.alias || [],
    redirect: languagegua6UEIwycMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_937tx4GV4yLpMeta?.name ?? "settings-organisations-id",
    path: _91id_937tx4GV4yLpMeta?.path ?? "organisations/:id()",
    children: [
  {
    name: organisationActivities0bpHsxHlpWMeta?.name ?? "settings-organisations-id-organisationActivities",
    path: organisationActivities0bpHsxHlpWMeta?.path ?? "organisationActivities",
    meta: organisationActivities0bpHsxHlpWMeta || {},
    alias: organisationActivities0bpHsxHlpWMeta?.alias || [],
    redirect: organisationActivities0bpHsxHlpWMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationActivities.vue").then(m => m.default || m)
  },
  {
    name: organisationBaseDatac00QnwK23uMeta?.name ?? "settings-organisations-id-organisationBaseData",
    path: organisationBaseDatac00QnwK23uMeta?.path ?? "organisationBaseData",
    meta: organisationBaseDatac00QnwK23uMeta || {},
    alias: organisationBaseDatac00QnwK23uMeta?.alias || [],
    redirect: organisationBaseDatac00QnwK23uMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  },
  {
    name: organisationCategoriespLHXQQjZjhMeta?.name ?? "settings-organisations-id-organisationCategories",
    path: organisationCategoriespLHXQQjZjhMeta?.path ?? "organisationCategories",
    meta: organisationCategoriespLHXQQjZjhMeta || {},
    alias: organisationCategoriespLHXQQjZjhMeta?.alias || [],
    redirect: organisationCategoriespLHXQQjZjhMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationCategories.vue").then(m => m.default || m)
  },
  {
    name: organisationUsersA4vg1DzHxvMeta?.name ?? "settings-organisations-id-organisationUsers",
    path: organisationUsersA4vg1DzHxvMeta?.path ?? "organisationUsers",
    meta: organisationUsersA4vg1DzHxvMeta || {},
    alias: organisationUsersA4vg1DzHxvMeta?.alias || [],
    redirect: organisationUsersA4vg1DzHxvMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id]/organisationUsers.vue").then(m => m.default || m)
  }
],
    meta: _91id_937tx4GV4yLpMeta || {},
    alias: _91id_937tx4GV4yLpMeta?.alias || [],
    redirect: _91id_937tx4GV4yLpMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939SJCAVoi1QMeta?.name ?? "settings-users-id",
    path: _91id_939SJCAVoi1QMeta?.path ?? "users/:id()",
    children: [
  {
    name: userActivitiesRkowh9b3OTMeta?.name ?? "settings-users-id-userActivities",
    path: userActivitiesRkowh9b3OTMeta?.path ?? "userActivities",
    meta: userActivitiesRkowh9b3OTMeta || {},
    alias: userActivitiesRkowh9b3OTMeta?.alias || [],
    redirect: userActivitiesRkowh9b3OTMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataRerpdZ2zuKMeta?.name ?? "settings-users-id-userBaseData",
    path: userBaseDataRerpdZ2zuKMeta?.path ?? "userBaseData",
    meta: userBaseDataRerpdZ2zuKMeta || {},
    alias: userBaseDataRerpdZ2zuKMeta?.alias || [],
    redirect: userBaseDataRerpdZ2zuKMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id]/userBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_939SJCAVoi1QMeta || {},
    alias: _91id_939SJCAVoi1QMeta?.alias || [],
    redirect: _91id_939SJCAVoi1QMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings/users/[id].vue").then(m => m.default || m)
  }
],
    meta: settingsI55iluHhQ7Meta || {},
    alias: settingsI55iluHhQ7Meta?.alias || [],
    redirect: settingsI55iluHhQ7Meta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: signaturee5nm2afNvqMeta?.name ?? "signature",
    path: signaturee5nm2afNvqMeta?.path ?? "/signature",
    meta: signaturee5nm2afNvqMeta || {},
    alias: signaturee5nm2afNvqMeta?.alias || [],
    redirect: signaturee5nm2afNvqMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/signature.vue").then(m => m.default || m)
  },
  {
    name: statisticsdJ2dx5BqueMeta?.name ?? "statistics",
    path: statisticsdJ2dx5BqueMeta?.path ?? "/statistics",
    meta: statisticsdJ2dx5BqueMeta || {},
    alias: statisticsdJ2dx5BqueMeta?.alias || [],
    redirect: statisticsdJ2dx5BqueMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cAHGV0QFGaMeta?.name ?? "wiki-slug",
    path: _91_46_46_46slug_93cAHGV0QFGaMeta?.path ?? "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93cAHGV0QFGaMeta || {},
    alias: _91_46_46_46slug_93cAHGV0QFGaMeta?.alias || [],
    redirect: _91_46_46_46slug_93cAHGV0QFGaMeta?.redirect || undefined,
    component: () => import("/home/forge/jugendzaehlt.oase-bw.de/releases/20240423175141/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]