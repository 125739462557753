import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTableFilterBoolean, LazyTableFilterNewFilterTemplate, LazyTableFilterNumeric, LazyTableFilterSelect, LazyTableFilterString, LazyTableToolFormsCreate, LazyTableToolFundingProgramsCreate, LazyTableToolGeneralExport, LazyTableToolLoginProvidersCreate, LazyTableToolOrganisationUsersCreate, LazyTableToolOrganisationsCreate, LazyTableToolOrganisationsToggle, LazyTableToolRegionsExport, LazyTableToolRegionsTokens, LazyTableToolStatusesCreate, LazyTableToolUserOrganisationsCreate, LazyTableToolUsersCreate, LazyTableToolUsersDelete, LazyBasicHeading, LazyBasicText, LazySubheading, LazyPostLoader, LazyKJPKT, LazyKJPPW, LazyKJPRM, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["TableFilterBoolean", LazyTableFilterBoolean],
["TableFilterNewFilterTemplate", LazyTableFilterNewFilterTemplate],
["TableFilterNumeric", LazyTableFilterNumeric],
["TableFilterSelect", LazyTableFilterSelect],
["TableFilterString", LazyTableFilterString],
["TableToolFormsCreate", LazyTableToolFormsCreate],
["TableToolFundingProgramsCreate", LazyTableToolFundingProgramsCreate],
["TableToolGeneralExport", LazyTableToolGeneralExport],
["TableToolLoginProvidersCreate", LazyTableToolLoginProvidersCreate],
["TableToolOrganisationUsersCreate", LazyTableToolOrganisationUsersCreate],
["TableToolOrganisationsCreate", LazyTableToolOrganisationsCreate],
["TableToolOrganisationsToggle", LazyTableToolOrganisationsToggle],
["TableToolRegionsExport", LazyTableToolRegionsExport],
["TableToolRegionsTokens", LazyTableToolRegionsTokens],
["TableToolStatusesCreate", LazyTableToolStatusesCreate],
["TableToolUserOrganisationsCreate", LazyTableToolUserOrganisationsCreate],
["TableToolUsersCreate", LazyTableToolUsersCreate],
["TableToolUsersDelete", LazyTableToolUsersDelete],
["BasicHeading", LazyBasicHeading],
["BasicText", LazyBasicText],
["Subheading", LazySubheading],
["PostLoader", LazyPostLoader],
["KJPKT", LazyKJPKT],
["KJPPW", LazyKJPPW],
["KJPRM", LazyKJPRM],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
